var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reporting.daily")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("reporting.daily")))]), _c('h6', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("toHumanDate")(_vm.searchDate)))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 card border-0"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 col-form-label",
    attrs: {
      "for": "staticEmail"
    }
  }, [_vm._v(_vm._s(_vm.$t("date")))]), _c('div', {
    staticClass: "col-md-6 col-lg-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchDate,
      expression: "searchDate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.searchDate
    },
    on: {
      "change": _vm.getWasiats,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchDate = $event.target.value;
      }
    }
  })])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-8 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0 card border-0"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("no-of-wills")) + ": " + _vm._s(_vm.pagination.totalRows) + " ")])])])])]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-2"
  }, [_c('table', {
    staticClass: "table mb-0 table-center",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("amendment.no-wasiat")))]), _c('th', [_vm._v(_vm._s(_vm.$t("date-of-will")))]), _c('th', [_vm._v(_vm._s(_vm.$t("testator-name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("testator-mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("business-partner")))]), _c('th', [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('th', [_vm._v(_vm._s(_vm.$t("agent-name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("referral-no")))]), _c('th', [_vm._v(_vm._s(_vm.$t("wasiatasas.price")))])])]), _c('tbody', [_vm._m(0), _vm._l(_vm.wasiats, function (wasiat, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s((_vm.pagination.currentPage - 1) * _vm.pagination.perPage + index + 1) + " ")]), _c('td', [_vm._v(_vm._s(wasiat.wasiat_no))]), _c('td', [_vm._v(_vm._s(_vm._f("formatDateTime")(wasiat.created_at)))]), _c('td', [_vm._v(_vm._s(wasiat.user.name))]), _c('td', [_vm._v(_vm._s(wasiat.user.ic_number))]), _c('td', [_vm._v(" " + _vm._s(wasiat.organization ? wasiat.organization.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.branch ? wasiat.branch.name : "-") + " ")]), _c('td', [_vm._v(_vm._s(wasiat.agent ? wasiat.agent.name : "-"))]), _c('td', [_vm._v(_vm._s(wasiat ? wasiat.referrer_number : "-"))]), _c('td', [_vm._v("RM " + _vm._s(wasiat.price))])]);
  }), _vm.wasiats.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "11"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.no-will")))])])]) : _vm._e()], 2)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    attrs: {
      "colspan": "9"
    }
  })]);
}]

export { render, staticRenderFns }